import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import InstagramFeed from "../../components/InstagramFeed";
import HeroHeader from "../../components/HeroHeader";
import StepsToStart from "../../sections/StepsToStart";
import OurLocations from "../../sections/OurLocations";
import PreTrainingQuestionnaireForm from "../../components/Forms/PreTrainingQuestionnaireForm";
import { navigate } from "gatsby-link";

const stepsToStartId = "steps";
const membershipOptionsId = "membership-options";
const preTrainingQuestionnaireId = "questionnaire";

const heroHeaderQuery = graphql`
    query {
        image: file(relativePath: { eq: "about-us-banner.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
        }
    }
`;

const PreTrainingQuestionnairePage = () => {
    const headerImage = useStaticQuery(heroHeaderQuery);
    const successfulSubmit = () => {
        navigate("/pre-training-questionnaire/submitted");
    };
    return (
        <Layout>
            <Seo
                title="Pre Training Questionnaire | FEAT Fitness"
                meta_title="Pre Training Questionnaire | FEAT Fitness"
                meta_desc="Our Pre Training Questionnaire is used to know what you want to get our of training, and ensure that everything is all well for you to get started."
                slug="pre-training-questionnaire"
            />
            <main>
                <div>
                    {/* Hero card */}
                    <HeroHeader
                        title={"Welcome to FEAT Fitness"}
                        background_image={headerImage?.image}
                        options={[
                            {
                                title: "Pre Training Questionnaire",
                                to: `#${preTrainingQuestionnaireId}`,
                                highlighted: true,
                            },
                            {
                                title: "First day of Training?",
                                to: `#${stepsToStartId}`,
                            },
                            {
                                title: "Membership Options",
                                to: `#${membershipOptionsId}`,
                            },
                        ]}
                        hideShading
                    />
                    {/* End hero */}

                    <div
                        className="bg-feat-lightgrey"
                        id={preTrainingQuestionnaireId}
                    >
                        <div className="max-w-4xl mx-auto py-24 px-6">
                            <PreTrainingQuestionnaireForm
                                headingClassNames="text-4xl text-heading uppercase text-center leading-normal	font-medium text-gray-900 pb-4"
                                afterSubmit={successfulSubmit}
                            />
                        </div>
                    </div>

                    {/* Map and Location Details */}
                    <OurLocations />
                    {/* END Map and Location Details */}

                    {/* Training with FEAT Fitness */}
                    <StepsToStart id={stepsToStartId} />
                    {/* END Training with FEAT Fitness */}

                    {/* Instagram Feed */}
                    <InstagramFeed />
                    {/* End Instagram Feed */}
                </div>
            </main>
        </Layout>
    );
};

export default PreTrainingQuestionnairePage;
